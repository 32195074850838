import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { injectIntl } from "react-intl";
import { setStep } from "../../actions/step";
import { calculatePbTag } from "../../actions/results";
import validate from "../../helpers/validateWizard";

import { startGetProducts } from "../../actions/products";

import { initialValues } from "../../config";

import ContentBox from "../../components/ContentBox/ContentBox";
import Divider from "../../components/Divider";
import ContentTitle from "../../components/ContentTitle";
import GenericInput from "../../components/GenericInput";
import InputGroup from "../../components/InputGroup";
import AnimatedSelect from "../../components/AnimatedSelect";
import RadioInput from "../../components/RadioInput";
import InfoBox from "../../components/InfoBox";

class Step1 extends Component {
  constructor(props) {
    super(props);

    this.step = 1;

    this._onSubmit = this._onSubmit.bind(this);
    this._onInputClick = this._onInputClick.bind(this);
  }

  componentDidMount() {
    const {
      getContainerHeight,
      calculateScrollOffset,
      startGetProducts
    } = this.props;

    const offline = navigator.onLine === false;

    getContainerHeight(this.step);
    startGetProducts(offline);

    document.getElementById(this.step).addEventListener("mouseenter", () => {
      calculateScrollOffset(this.step);
      getContainerHeight(this.step);
    });
  }

  componentDidUpdate() {
    const { step, getContainerHeight, values, calculatePbTag } = this.props;
    getContainerHeight(this.step);
    if (step > this.step) {
      calculatePbTag(values);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.step === 1 && this.props.step === 7) {
      window.location.reload();
    }
  }

  /**
   * _onSubmit() calculates pbTag and sets next wizard step
   */
  _onSubmit() {
    const { setStep, calculatePbTag, values } = this.props;
    setStep(this.step + 1);
    calculatePbTag(values);
  }

  /**
   * _inputOnClick() changes a custom redux field based on up/down arrow function
   * @param {String} direction
   * @param {String} field
   */
  _onInputClick(direction, field) {
    const { changeFieldValue, values } = this.props;
    const fieldValue = parseInt(values[field]);

    changeFieldValue(
      "wizard",
      field,
      direction === "up" ? fieldValue + 1 : fieldValue - 1
    );
  }

  render() {
    const {
      step,
      handleSubmit,
      intl,
      values,
      error,
      productsAvailable
    } = this.props;

    const animationOptions = {
      in: {
        loop: false,
        autoplay: false,
        animationData: require("../../animations/frau_mann_in.json"),
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      },
      out: {
        loop: false,
        autoplay: false,
        animationData: require("../../animations/frau_mann_out.json"),
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }
    };

    if (error && !productsAvailable) {
      return (
        <React.Fragment>
          <ContentBox id={this.step} active={step === this.step}>
            <InfoBox withIcon="icon-warning" iconSize={"25px"}>
              {error}
            </InfoBox>
          </ContentBox>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ContentBox id={this.step} active={step === this.step}>
          <form>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <ContentTitle
                  step={this.step}
                  title={intl.formatMessage({ id: "step1.title" })}
                />
              </div>
              <div className="col-lg-9 col-md-12">
                <InputGroup>
                  <Field
                    name="age"
                    type="number"
                    validate={age => age < 18}
                    clickDownMinValue={18}
                    label={intl.formatMessage({ id: "step1.age.label" })}
                    description={intl.formatMessage({
                      id: "step1.age.description"
                    })}
                    component={GenericInput}
                    onClickUp={() => this._onInputClick("up", "age")}
                    onClickDown={() => this._onInputClick("down", "age")}
                  />

                  <Field
                    name="weight"
                    type="number"
                    validate={weight => weight < 1}
                    clickDownMinValue={1}
                    label={intl.formatMessage({ id: "step1.weight.label" })}
                    description={intl.formatMessage({
                      id: "step1.weight.description"
                    })}
                    component={GenericInput}
                    onClickUp={() => this._onInputClick("up", "weight")}
                    onClickDown={() => this._onInputClick("down", "weight")}
                  />
                  <AnimatedSelect
                    description={intl.formatMessage({
                      id: "step1.gender.description"
                    })}
                    gender={values.sex}
                    animationOptions={animationOptions}
                    changeFieldValue={this.props.changeFieldValue}
                  />
                </InputGroup>
                {values.sex === "female" && (
                  <React.Fragment>
                    <InputGroup
                      width="80%"
                      title={intl.formatMessage({ id: "step1.pregnant.title" })}
                    >
                      <Field
                        name="pregnant"
                        type="radio"
                        value="yes"
                        component={RadioInput}
                        label={intl.formatMessage({
                          id: "step1.pregnant.label.option1"
                        })}
                        width={"150px"}
                      />
                      <Field
                        name="pregnant"
                        type="radio"
                        value="suckle"
                        component={RadioInput}
                        label={intl.formatMessage({
                          id: "step1.pregnant.label.option2"
                        })}
                        width={"150px"}
                      />
                      <Field
                        name="pregnant"
                        type="radio"
                        value="no"
                        component={RadioInput}
                        label={intl.formatMessage({
                          id: "step1.pregnant.label.option3"
                        })}
                        width={"150px"}
                      />
                    </InputGroup>
                  </React.Fragment>
                )}
                {values.sex === "female" && values.pregnant === "yes" && (
                  <InputGroup width="80%">
                    <Field
                      name="pregnantIn"
                      type="radio"
                      value="1"
                      component={RadioInput}
                      label={intl.formatMessage({
                        id: "step1.pregnantIn.label.option1"
                      })}
                      width={"150px"}
                    />
                    <Field
                      name="pregnantIn"
                      type="radio"
                      value="2"
                      component={RadioInput}
                      label={intl.formatMessage({
                        id: "step1.pregnantIn.label.option2"
                      })}
                      width={"150px"}
                    />
                    <Field
                      name="pregnantIn"
                      type="radio"
                      value="3"
                      component={RadioInput}
                      label={intl.formatMessage({
                        id: "step1.pregnantIn.label.option3"
                      })}
                      width={"150px"}
                    />
                  </InputGroup>
                )}
              </div>
            </div>
          </form>
        </ContentBox>
        <Divider
          withButton={step <= 1}
          buttonTitle={intl.formatMessage({ id: "button.next" }).toLowerCase()}
          buttonOnClick={handleSubmit(this._onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    step: state.step,
    values: state.form.wizard.values,
    error: state.messages.error,
    productsAvailable: state.products.length > 0
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStep: step => dispatch(setStep(step)),
    calculatePbTag: values => dispatch(calculatePbTag(values)),
    startGetProducts: offline => dispatch(startGetProducts(offline)),
    changeFieldValue: (form, field, value) =>
      dispatch(change(form, field, value))
  };
};

export default reduxForm({
  form: "wizard",
  destroyOnUnmount: false,
  validate,
  initialValues
})(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Step1)
  )
);
