import React, { Component } from "react";
import { Provider } from "react-redux";
import { IntlProvider, addLocaleData } from "react-intl";
import { ThemeProvider } from "styled-components";
import en from "react-intl/locale-data/en";
import de from "react-intl/locale-data/de";
import store from "./store/configureStore";
import themes from "./themes";
import translationsDE from "./i18n/de.json";
import Wizard from "./wizard";

addLocaleData([...en, ...de]);

export class App extends Component {
  render() {
    
    return (
      <IntlProvider
        locale="de"
        messages={translationsDE}
        textComponent={React.Fragment}
      >
        <ThemeProvider theme={themes.defaultTheme}>
          <ThemeProvider theme={themes.krueger}>
            <Provider store={store}>
              <React.Fragment>
                <Wizard />
              </React.Fragment>
            </Provider>
          </ThemeProvider>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default App;
