import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Icon from "../Icon";

const InfoBoxWraper = styled.div`
  border: 2px solid ${props => props.theme.grey2};
  margin: 40px 0px;

  ${props =>
    props.withIcon &&
    css`
      display: flex;
      justify-content: space-around;
      align-items: center;
    `}
`;

const Text = styled.p`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 0px;
`;

const InfoBox = ({ children, withIcon, iconSize }) => {
  return (
    <InfoBoxWraper withIcon={withIcon}>
      {withIcon && <Icon size={iconSize} className={withIcon} />}
      <Text>{children}</Text>
    </InfoBoxWraper>
  );
};

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  withIcon: PropTypes.string,
  iconSize: PropTypes.string
};

export default InfoBox;
