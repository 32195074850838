import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

const InputGroupWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: ${props => (props.wrap ? "wrap" : "no-wrap")};
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
  margin: 40px 0px;

  ${breakpoint("lg")`
    width: ${props => props.width};
  `}

  ${props =>
    props.autoMargin &&
    css`
      margin: auto;
    `}  

  ${props =>
    props.center &&
    css`
      margin: 40px auto;
    `}
`;

const Title = styled.div`
  font-size: 22px;
  margin: 15px 0px;
  padding: 0px 15px;

  ${breakpoint("lg")`
    padding: 0px;
`}
`;

const InputGroup = ({
  children,
  title,
  width,
  center,
  justifyContent,
  wrap,
  autoMargin
}) => {
  return (
    <React.Fragment>
      {title && <Title>{title}</Title>}
      <InputGroupWrapper
        autoMargin={autoMargin}
        wrap={wrap}
        justifyContent={justifyContent}
        width={width}
        center={center}
      >
        {children}
      </InputGroupWrapper>
    </React.Fragment>
  );
};

InputGroup.defaultProps = {
  width: "100%",
  center: false,
  wrap: true,
  justifyContent: "space-between",
  autoMargin: false
};

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  center: PropTypes.bool,
  justifyContent: PropTypes.string,
  wrap: PropTypes.bool,
  autoMargin: PropTypes.bool
};

export default InputGroup;
