import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { animateScroll as scroll } from "react-scroll";
import breakpoint from "styled-components-breakpoint";
import { scrollTo } from "../../helpers/iframe";

const NavigationWrapper = styled.div`
  display: none;
  align-items: center;
  position: absolute;
  transition: top 700ms ease 200ms;
  z-index: 10;
  height: ${props => `${props.height}px`};

  ${props =>
    props.sticky &&
    css`
      display: flex;
      position: relative;
      background-color: ${props => props.theme.white};
    `}

  ${breakpoint("sm")`
      display: flex;

      ${props =>
        props.sticky &&
        css`
          display: none;
        `}
`}

  ${props =>
    props.top &&
    css`
      top: ${props => `${props.top}px`};
    `}
`;

const StepWrapper = styled.div`
  ${props =>
    props.sticky &&
    css`
      display: flex;
      margin: auto;
    `}
`;

const Step = styled.div`
  cursor: pointer;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.grey2};
  border-radius: 100%;

  height: 24px;
  width: 24px;
  margin: 20px 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint("xl")`
    margin: 20px;
  `}

  ${props =>
    props.active &&
    css`
      background-color: ${props => props.theme.primary};
      border: 1px solid ${props => props.theme.grey2};
    `};

  ${props =>
    props.visited &&
    css`
      background-color: ${props => props.theme.success};
      color: ${props => props.theme.white}
      border: 3px solid ${props => props.theme.success};
    `};
`;

Step.propTypes = {
  active: PropTypes.bool,
  visited: PropTypes.bool
};

const Text = styled.span`
  font-size: 13px;
  color: ${props => props.theme.black};
  font-family: ${props => props.theme.ffBold};

  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.white};
    `};
`;

Text.propTypes = {
  active: PropTypes.bool
};

const Icon = styled.span`
  font-size: 10px;
`;

const steps = [[1], [2], [3], [4], [5], [6]];

const Navigation = ({ step, height, top, sticky, style }) => {
  const activeStep = Math.floor(step);
  const topOffset = Math.floor(top);

  return (
    <NavigationWrapper
      style={style}
      sticky={sticky}
      top={topOffset}
      height={height}
    >
      <StepWrapper sticky={sticky}>
        {steps.map((singleStep, index) => {
          const active = singleStep[0] === activeStep;
          const visited = singleStep[0] < activeStep;

          return (
            <Step
              onClick={() => {
                const step = index + 1;
                if (visited || active) {
                  const offset = document.getElementById(step).offsetTop;
                  scroll.scrollTo(offset);
                  scrollTo(offset);
                }
              }}
              key={singleStep[0]}
              visited={visited}
              active={active}
            >
              {visited ? (
                <Icon className="icon-checkmark" />
              ) : (
                <Text active={active}>{singleStep[0]}</Text>
              )}
            </Step>
          );
        })}
      </StepWrapper>
    </NavigationWrapper>
  );
};

Navigation.propTypes = {
  step: PropTypes.number,
  height: PropTypes.number,
  top: PropTypes.number,
  sticky: PropTypes.bool,
  style: PropTypes.object
};

export default Navigation;
