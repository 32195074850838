import { reducer as formReducer } from "redux-form";
import resultsReducer from "./results";
import stepReducer from "./step";
import productsReducer from "./products";
import messagesReducer from "./messages";

const reducers = {
  form: formReducer,
  results: resultsReducer,
  step: stepReducer,
  products: productsReducer,
  messages: messagesReducer
};

export default reducers;
