import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const IconWrapper = styled.span`
  font-size: ${props => props.size};
  padding: 5px;

  ${props => props.absolute && css`
    position: absolute;
  `}
`;

const Icon = ({ className, size, onClick, absolute, style }) => (
  <IconWrapper style={style} onClick={onClick} size={size} className={className} absolute={absolute} />
);

Icon.defaultProps = {
  size: "19px"
};

Icon.propTypes = {
  className: PropTypes.string.isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
};

export default Icon;
