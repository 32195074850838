import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { animateScroll as scroll } from "react-scroll";
import { setStep } from "../../actions/step";
import validate from "../../helpers/validateWizard";

import { AlternativeWrapper } from "../../components/GenericInput";
import GenericInput from "../../components/GenericInput";
import RadioInput from "../../components/RadioInput";
import ContentBox from "../../components/ContentBox/ContentBox";
import ContentTitle from "../../components/ContentTitle";
import Divider from "../../components/Divider";
import { scrollTo } from "../../helpers/iframe";

class Step6 extends Component {
  constructor(props) {
    super(props);

    this.step = 6;

    this._onSubmit = this._onSubmit.bind(this);
  }

  componentDidMount() {
    const { getContainerHeight, calculateScrollOffset } = this.props;

    const offset = document.getElementById(this.step).offsetTop;
    scroll.scrollTo(offset);
    scrollTo(offset);

    getContainerHeight(this.step);
    calculateScrollOffset(this.step);

    document.getElementById(this.step).addEventListener("mouseenter", () => {
      calculateScrollOffset(this.step);
      getContainerHeight(this.step);
    });
  }

  _onSubmit() {
    const { setStep } = this.props;
    setStep(this.step + 1);
  }

  render() {
    const { step, intl, handleSubmit, values, theme } = this.props;
    return (
      <React.Fragment>
        <ContentBox id={this.step} active={step === this.step}>
          <form>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <ContentTitle
                  step={6}
                  title={intl.formatMessage({ id: "step6.title" })}
                />
              </div>

              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-lg-4 col-6">
                    <Field
                      size="small"
                      label={intl.formatMessage({
                        id: "step6.preparation.label.option1"
                      })}
                      type="radio"
                      component={RadioInput}
                      name="preparation"
                      value="water"
                      labelImage={theme.waterImage}
                    />
                  </div>
                  <div className="col-lg-4 col-6">
                    <Field
                      size="small"
                      label={intl.formatMessage({
                        id: "step6.preparation.label.option2"
                      })}
                      type="radio"
                      component={RadioInput}
                      name="preparation"
                      value="milk"
                      labelImage={theme.milkImage}
                    />
                  </div>
                  <div className="col-lg-4 col-6">
                    <Field
                      size="small"
                      label={intl.formatMessage({
                        id: "step6.preparation.label.option3"
                      })}
                      type="radio"
                      component={RadioInput}
                      name="preparation"
                      value="vegan"
                      labelImage={theme.veganImage}
                    >
                      {values.preparation === "vegan" && (
                        <AlternativeWrapper>
                          <Field
                            key="2"
                            validate={preparationAlt => preparationAlt <= 1}
                            description={intl.formatMessage({
                              id: "step6.preparation.description.option4"
                            })}
                            type="number"
                            label={intl.formatMessage({
                              id: "step6.preparation.label.option4"
                            })}
                            component={GenericInput}
                            name="preparationAlt"
                            black
                            withArrows={false}
                            alternative
                          />
                        </AlternativeWrapper>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ContentBox>
        <Divider
          withButton={step <= 6}
          buttonTitle={intl.formatMessage({ id: "button.next" }).toLowerCase()}
          buttonOnClick={handleSubmit(this._onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    step: state.step,
    values: state.form.wizard.values
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStep: step => dispatch(setStep(step))
  };
};

export default reduxForm({
  form: "wizard",
  destroyOnUnmount: false,
  validate
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(withTheme(Step6)))
);
