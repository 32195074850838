import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  border-radius: 100%;
  padding: 20px;

  ${props =>
    props.size === "small" &&
    css`
      width: 130px;
      height: 130px;
      border: 2px solid ${props => props.theme.grey3};
    `}

  ${props =>
    props.size === "large" &&
    css`
      width: 170px;
      height: 170px;
      border: 4px solid ${props => props.theme.black};
    `}
`;

const Title = styled.span`
  line-height: 1;
  letter-spacing: -3px;
  font-weight: bold;

  ${props =>
    props.size === "small" &&
    css`
    font-size: ${props => props.fontSize ? "25px" : "40px"};
    color: ${props => props.theme.grey3};
    `}

  ${props =>
    props.size === "large" &&
    css`
      font-size: ${props => props.fontSize ? "40px" : "60px"};
    `}
`;

const SubTitle = styled.span`
  line-height: 1;
  font-weight: bold;

  ${props =>
    props.size === "small" &&
    css`
      font-size: 20px;
      color: ${props => props.theme.grey3};
    `}

  ${props =>
    props.size === "large" &&
    css`
      font-size: 25px;
    `}
`;

const Description = styled.span`
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  padding-top: 10px;

  ${props =>
    props.size === "small" &&
    css`
      color: ${props => props.theme.grey3};
    `}

  ${props =>
    props.size === "large" &&
    css`
      font-weight: bold;
    `}
`;

const Circle = ({ size, label, pb, description }) => {
  return (
    <Container>
      <CircleWrapper size={size}>
        <Title size={size} fontSize={pb.toString().length > 2 ? true : false}>
          {pb} g
        </Title>
        <SubTitle size={size}>{label}</SubTitle>
      </CircleWrapper>
      <Description size={size}>{description}</Description>
    </Container>
  );
};

Circle.defaultProps = {
  size: "small",
  label: "per Day",
  description: "description",
  pb: 20
};

Circle.propTypes = {
  size: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  pb: PropTypes.number.isRequired
};

export default Circle;
