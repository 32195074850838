import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Step = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: ${props => props.theme.primary};
  display: flex;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.white};
  font-family: ${props => props.theme.ffBold};
  font-size: 36px;
`;

const Title = styled.h2`
  color: ${props => props.theme.black};
  font-family: ${props => props.theme.ffBold};
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 0px;
`;

const ContentTitle = ({ step, title }) => {
  return (
    <React.Fragment>
      <Step>{step}</Step>
      <Title>{title}</Title>
    </React.Fragment>
  );
};

ContentTitle.defaultProps = {
  step: 1,
  title: "Title"
};

ContentTitle.propTypes = {
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired
};

export default ContentTitle;
