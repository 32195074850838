import React from "react";
import Sticky from "react-sticky-el";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ReactResizeDetector from "react-resize-detector";
import { setHeight } from "../helpers/iframe";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import Step7 from "./Steps/Step7";

import Navigation from "../components/Navigation";
import Divider from "../components/Divider";
import Header from "../components/Header";

class Wizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stepHeight: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 },
      scrollOffset: 0,
      scrollOffsetStep: undefined
    };

    this._getContainerHeight = this._getContainerHeight.bind(this);
    this._calculateScrollOffset = this._calculateScrollOffset.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.step === 1 && this.props.step === 7) {
      this.setState({ scrollOffset: 0 });
    }
  }

  _getContainerHeight(step) {
    const height = document.getElementById(step).scrollHeight;
    this.setState(prevState => {
      return { stepHeight: { ...prevState.stepHeight, [step]: height } };
    });
  }

  _calculateScrollOffset(step) {
    const scrollOffset = document.getElementById(step).offsetTop;
    this.setState({
      scrollOffset: scrollOffset,
      scrollOffsetStep: step
    });
  }

  render() {
    const { step, intl, error } = this.props;
    const { stepHeight, scrollOffset, scrollOffsetStep } = this.state;

    const stickySettingsMobile = {
      position: "absolute",
      zIndex: 600,
      top: 70
    };

    return (
      <React.Fragment>
        <ReactResizeDetector
          handleHeight
          onResize={(width, height) => setHeight(height)}
        />
        <Header
          title={intl.formatMessage({ id: "app.title" })}
          description={intl.formatMessage({ id: "app.description" })}
        />
        <Divider />

        {!error && (
          <Sticky stickyStyle={stickySettingsMobile}>
            <Navigation sticky step={step} />
          </Sticky>
        )}

        {!error && (
          <Navigation
            top={scrollOffset}
            height={stepHeight[scrollOffsetStep ? scrollOffsetStep : step]}
            step={step}
          />
        )}

        {step && step >= 1 && (
          <Step1
            calculateScrollOffset={this._calculateScrollOffset}
            getContainerHeight={this._getContainerHeight}
          />
        )}
        {step && step >= 2 && (
          <Step2
            calculateScrollOffset={this._calculateScrollOffset}
            getContainerHeight={this._getContainerHeight}
          />
        )}
        {step && step >= 3 && (
          <Step3
            calculateScrollOffset={this._calculateScrollOffset}
            getContainerHeight={this._getContainerHeight}
          />
        )}
        {step && step >= 4 && (
          <Step4
            calculateScrollOffset={this._calculateScrollOffset}
            getContainerHeight={this._getContainerHeight}
          />
        )}
        {step && step >= 5 && (
          <Step5
            calculateScrollOffset={this._calculateScrollOffset}
            getContainerHeight={this._getContainerHeight}
          />
        )}
        {step && step >= 6 && (
          <Step6
            calculateScrollOffset={this._calculateScrollOffset}
            getContainerHeight={this._getContainerHeight}
          />
        )}
        {step && step >= 7 && (
          <Step7
            calculateScrollOffset={this._calculateScrollOffset}
            getContainerHeight={this._getContainerHeight}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  step: state.step,
  error: state.messages.error
});

export default connect(mapStateToProps)(injectIntl(Wizard));
