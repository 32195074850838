import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const HeaderWrapper = styled.div`
    margin: 40px 20px 60px 20px;
`

const Title = styled.h1`
  font-size: 28px;
  line-height: 1.6;
  text-transform: uppercase;
  text-align: center;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
`;

const Header = ({ title, description }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <HeaderWrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </HeaderWrapper>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default Header;
