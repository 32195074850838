let backendHost;
// const apiURL = "rest/all/V1/proteinCoach";
const shopURL = "shop";
const defaultLanguage = "de";

const hostname = window && window.location && window.location.hostname;

if (
  hostname === "protein-coach.staging.maxinutrition.de" ||
  hostname === "protein-coach.staging.hnc.brandung.de" ||
  hostname === "localhost"
) {
  backendHost = "https://staging.maxinutrition.de";
}
if (
  hostname === "protein-coach.maxinutrition.de" ||
  hostname === "protein-coach.hnc.brandung.de" ||
  hostname === "protein-coach-offline.maxinutrition.de"
) {
  backendHost = "https://maxinutrition.de";
}

// const API_ROOT = `${backendHost}/${apiURL}`;
const API_ROOT = "https://maxinutrition.de/rest/maxinutrition_de_relaunch/V1/proteinCoach"
const SHOP_URL = `${backendHost}/${shopURL}`;

const initialValues = {
  age: 20,
  weight: 65,
  sex: "male",
  dailyreq: "percent",
  dailyreqgram: 25,
  dailyreqpercent: 25
}

export { API_ROOT, SHOP_URL, defaultLanguage, initialValues };
