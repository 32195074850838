import { injectGlobal } from "styled-components";
import styledNormalize from "styled-normalize";
import FFDINWebProRegularEot from "./assets/fonts/3655E8_1_0.eot";
import FFDINWebProRegularWoff from "./assets/fonts/3655E8_1_0.woff";
import FFDINWebProRegularWoff2 from "./assets/fonts/3655E8_1_0.woff2";
import FFDINWebProRegularTtf from "./assets/fonts/3655E8_1_0.ttf";
import FFDINWebProBoldEot from "./assets/fonts/3655E8_2_0.eot";
import FFDINWebProBoldWoff from "./assets/fonts/3655E8_2_0.woff";
import FFDINWebProBoldWoff2 from "./assets/fonts/3655E8_2_0.woff2";
import FFDINWebProBoldTtf from "./assets/fonts/3655E8_2_0.ttf";
import IcomoonEot from "./assets/fonts/icomoon.eot";
import IcomoonWoff from "./assets/fonts/icomoon.woff";
import IcomoonWoff2 from "./assets/fonts/icomoon.woff2";
import IcomoonSvg from "./assets/fonts/icomoon.svg";
import IcomoonTtf from "./assets/fonts/icomoon.ttf";
import waterImage from "./assets/img/MaxiNutrition_Proteincoach-Step-5-Wasser-img.png";
import milkImage from "./assets/img/MaxiNutrition_Proteincoach-Step-5-Milch-img.png";
import veganImage from "./assets/img/MaxiNutrition_Proteincoach-Step-5-Milch-alternative-img.png";
import step7description01 from "./assets/img/MaxiNutrition_Proteincoach-Step-7-description-01-img.png";
import step7description02 from "./assets/img/MaxiNutrition_Proteincoach-Step-7-description-02-img.png";
import step7description03 from "./assets/img/MaxiNutrition_Proteincoach-Step-7-description-03-img.png";
import step7arrow from "./assets/img/MaxiNutrition_Proteincoach-Step-7-arrow.png";
import step7plus from "./assets/img/MaxiNutrition_Proteincoach-Step-7-plus.png";
import stepActiveBackground from "./assets/img/endless_paperbg.jpg";
import sliderBackground from "./assets/img/background_slider.png";

const krueger = {
  /*
	==========================================================================
	Variables
	==========================================================================
	*/

  // Colors
  alert: "#cc000c",
  black: "#1d1d1b",
  primary: "#e30613",
  primaryHover: "#ff5e67",
  success: "#50c36c",
  error: "#cc000c",
  white: "#ffffff",
  grey: "#efefef",
  grey2: "#c8c8c8",
  grey3: "#979797",
  grey4: "#494949",

  // Fonts
  ffRegular: '"FFDINWebProRegular", Arial, sans-serif',
  ffBold: '"FFDINWebProBold", Arial, sans-serif',
  icomoon: '"icomoon" !important',

  // Images
  waterImage: waterImage,
  milkImage: milkImage,
  veganImage: veganImage,
  step7description01: step7description01,
  step7description02: step7description02,
  step7description03: step7description03,
  step7arrow: step7arrow,
  step7plus: step7plus,
  stepActiveBackground,
  sliderBackground: sliderBackground,

  // Breakpoints
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
};

injectGlobal`
${styledNormalize}

@import url("//hello.myfonts.net/count/3655e8");
@font-face {font-family: 'FFDINWebProRegular';src: url(${FFDINWebProRegularEot});src: url('./assets/fonts/3655E8_1_0.eot?#iefix') format('embedded-opentype'),url(${FFDINWebProRegularWoff2}) format('woff2'),url(${FFDINWebProRegularWoff}) format('woff'),url(${FFDINWebProRegularTtf}) format('truetype');}
@font-face {font-family: 'FFDINWebProBold';src: url(${FFDINWebProBoldEot});src: url('./assets/fonts/3655E8_2_0.eot?#iefix') format('embedded-opentype'),url(${FFDINWebProBoldWoff2}) format('woff2'),url(${FFDINWebProBoldWoff}) format('woff'),url(${FFDINWebProBoldTtf}) format('truetype');}
@font-face {font-family: 'icomoon';src: url(${IcomoonEot});src: url('./assets/fonts/icomoon.eot?bmi69y#iefix') format('embedded-opentype'),url(${IcomoonWoff2}) format('woff2'),url(${IcomoonTtf}) format('truetype'),url(${IcomoonWoff}) format('woff'),url(${IcomoonSvg}) format('svg');font-weight: normal;font-style: normal;}

[class^="icon-"], [class*=" icon-"] {
	font-family: ${krueger.icomoon};
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
	content: "\\e900";
}
.icon-arrow-left:before {
	content: "\\e901";
}
.icon-arrow-right:before {
	content: "\\e902";
}
.icon-arrow-up:before {
	content: "\\e903";
}
.icon-checkmark:before {
	content: "\\e905";
	color: white;
}
.icon-warning:before {
	content: "\\e914";
}

body {
	font-family: ${krueger.ffRegular};
	color: ${krueger.black};
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-family: ${krueger.ffRegular};
}

h1, .h1 {
	font-size: 25px;
}

h2, .h2 {
	font-family: ${krueger.ffBold};
	font-size: 28px;
	margin-bottom: 10px;
	text-align: center;
}

h3, .h3 {
	color: ${krueger.grey};
	font-size: 22px;
	margin-bottom: 9px;
}

h4, .h4 {
	color: ${krueger.grey};
	font-size: 18px;
	margin-bottom: 9px;
}

p, li {
	font-size: 16px;
}

b {
	color: ${krueger.black};
	font-family: ${krueger.ffBold};
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield;
}
`;

export default krueger;
