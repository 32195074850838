import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Icon from "../Icon";

export const AlternativeWrapper = styled.div`
  position: absolute;
  left: 120px;
  top: 0px;

  ${breakpoint("sm")`
    top: -30px;
    left: 170px;
  `}
  ${breakpoint("md")`
    top: -2px;
    left: 210px;
  `}

  ${breakpoint("lg")`
    top: -2px;
    left: 60px;
  `}

  ${breakpoint("xl")`
    top: -2px;
    left: 170px;
  `}
`;

const GenericInputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
  padding: 0px 15px;

  ${props =>
    props.alternative &&
    css`
      flex-direction: column;
    `}

  ${breakpoint("md")`
    width: auto !important;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
  `}
`;

const Description = styled.span`
  font-size: 22px;
  margin: 0px;
  color: ${props => (props.error ? props.theme.error : props.theme.black)};
  padding-bottom: 25px;

  ${breakpoint("md")`
      width: 100%;
      margin: 15px 0px;
      padding: 0px;
`}

  ${props =>
    props.alternative &&
    css`
      font-size: 16px;
      margin: 10px 5px;
    `}
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const Input = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column;

  ${props =>
    props.alternative &&
    css`
      flex-direction: row;
    `}
`;

const Field = styled.input`
  width: 105px;
  height: 70px;
  border: 1px solid
    ${props => (props.error ? props.theme.error : props.theme.grey2)};
  text-align: center;
  box-shadow: inset 0 1px 13px 0 rgba(0, 0, 0, 0.17);
  font-size: 40px;

  ::placeholder {
    text-align: center;
    color: ${props => (props.error ? props.theme.error : props.theme.grey2)};
  }

  ${props =>
    props.alternative &&
    css`
      width: 110px;
      height: 32px;
      font-size: 20px;
    `}
`;

const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 70px;
`;

const Label = styled.label`
  font-size: 16px;
  margin: 5px;
  color: ${props => (props.error ? props.theme.error : props.theme.black)};

  ${props =>
    props.alternative &&
    css`
      width: 80px;
    `}
`;

const GenericInput = ({
  label,
  placeholder,
  description,
  onClickUp,
  onClickDown,
  clickDownMinValue,
  input,
  type,
  meta: { touched, error },
  withArrows,
  alternative
}) => {
  const fieldError = touched && error;

  return (
    <GenericInputWrapper alternative={alternative}>
      <Description alternative={alternative} error={fieldError}>
        {description}
      </Description>
      <InputWrapper>
        <Input alternative={alternative}>
          <Field
            alternative={alternative}
            error={fieldError}
            type={type}
            {...input}
            placeholder={placeholder}
          />
          <Label alternative={alternative} error={fieldError}>
            {label}
          </Label>
        </Input>
        {withArrows && (
          <Arrows>
            <Icon onClick={onClickUp} className="icon-arrow-up" />
            <Icon
              style={{ display: clickDownMinValue >= input.value ? "none" : "block" }}
              onClick={onClickDown}
              className="icon-arrow-down"
            />
          </Arrows>
        )}
      </InputWrapper>
    </GenericInputWrapper>
  );
};

GenericInput.defaultProps = {
  label: "Years",
  placeholder: 0,
  description: "How old are you?",
  withArrows: true,
  onClickUp: () => console.log("click up"),
  onClickDown: () => console.log("click down")
};

GenericInput.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  withArrows: PropTypes.bool,
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func,
  clickDownMinValue: PropTypes.number
};

export default GenericInput;
