import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

const RadioInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0px;

  ${breakpoint("md")`
    flex-basis: auto;
    justify-content: center;
  `}

  ${props =>
    props.width &&
    css`
      width: ${props => props.width};
    `};

  ${props =>
    props.horizontal &&
    css`
      flex-direction: row;
      justify-content: flex-start;
    `};
`;

const Checkmark = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid grey;
  box-shadow: inset 0 1px 13px 0 rgba(0, 0, 0, 0.17);

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  ${props =>
    props.error &&
    css`
      border: 1px solid ${props => props.theme.error};
    `}

  ${props =>
    props.size === "small" &&
    css`
      width: 35px;
      height: 35px;
    `}
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + ${Checkmark} {
    &:after {
      position: relative;
      display: block;
    }
  }
`;

const Container = styled.label`
  display: block;
  position: relative;
  width: 50px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  min-height: 50px;

  ${props =>
    props.size === "small" &&
    css`
      width: 35px;
      min-height: 35px;
    `};

  &:hover ${Input} + ${Checkmark} {
    background-color: ${props => props.theme.primary};
  }

  ${Checkmark} {
    &:after {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: ${props => props.theme.primary};

      ${props =>
        props.size === "small" &&
        css`
          width: 25px;
          height: 25px;
        `}
    }
  }
`;

const Label = styled.span`
  text-align: center;
  font-size: 16px;
  padding-top: 15px;

  ${props =>
    props.top &&
    css`
      padding-top: 0px;
      padding-bottom: 15px;
      color: #7d7d7d;
    `}
  ${props =>
    props.error &&
    css`
      color: ${props => props.theme.error};
    `}

  ${props =>
    props.horizontal &&
    css`
      padding-top: 0px;
      padding-left: 15px;
    `};
`;

const LabelImage = styled.img`
  max-width: 93px;
`;

const RadioInput = ({
  label,
  input,
  meta: { touched, error },
  type,
  width,
  size,
  horizontal,
  labelPosition,
  labelImage,
  children
}) => {
  const formError = touched && error;
  return (
    <RadioInputWrapper horizontal={horizontal} width={width && width}>
      {children}
      {labelImage && <LabelImage alt="" src={labelImage} />}
      {labelPosition === "top" && (
        <Label horizontal={horizontal} error={formError} top>
          {label}
        </Label>
      )}
      <Container size={size}>
        <Input checked {...input} type={type} />
        <Checkmark size={size} error={formError} />
      </Container>
      {labelPosition === "bottom" && (
        <Label horizontal={horizontal} error={formError} bottom>
          {label}
        </Label>
      )}
    </RadioInputWrapper>
  );
};

RadioInput.defaultProps = {
  label: "label",
  size: "large",
  horizontal: false,
  labelPosition: "bottom"
};

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  horizontal: PropTypes.bool,
  labelPosition: PropTypes.string
};

export default RadioInput;
