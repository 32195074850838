import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";

/**
 * setMessage() returns an action with a message and messageType.
 * This function can be used for async actions to set a custom error / success message
 *
 * @param {String} message
 * @param {String} messageType
 */
export const setMessage = (message, messageType) => {
    return {
        type: SET_MESSAGE,
        message,
        messageType
    }
}

/**
 * clearMessage() returns an action with a messageType as payload.
 * This function can be used for async actions to clear a message before settings a new one
 *
 * @param {String} messageTyoe
 */
export const clearMessage = (messageType) => {
    return {
        type: CLEAR_MESSAGE,
        payload: messageType
    }
}