import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Button from "../Button";

const StyledDivider = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  border: 1px dashed ${props => props.theme.grey};

  ${props =>
    props.margin &&
    css`
      margin: ${props => `${props.margin}px 0px`};
    `}

    ${props =>
      props.withButton &&
      css`
        margin: 10px 0px 60px 0px;
      `}

  ${props =>
    props.dark &&
    css`
      border: 1px dashed ${props => props.theme.grey4};
    `}
`;

const Divider = ({
  withButton,
  buttonTitle,
  buttonOnClick,
  buttonSize,
  margin,
  dark,
  container
}) => {
  if (!container) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <StyledDivider dark={dark} margin={margin} />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <StyledDivider dark={dark} withButton={withButton} margin={margin}>
            {withButton && (
              <Button
                type="submit"
                title={buttonTitle}
                onClick={buttonOnClick}
                size={buttonSize}
              />
            )}
          </StyledDivider>
        </div>
      </div>
    </div>
  );
};

Divider.defaultProps = {
  withButton: false,
  dark: false,
  container: true,
  buttonTitle: "next",
  buttonOnClick: () => console.log("clicked")
};

Divider.propTypes = {
  withButton: PropTypes.bool,
  buttonTitle: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonSize: PropTypes.string,
  margin: PropTypes.number,
  dark: PropTypes.bool,
  container: PropTypes.bool
};

export default Divider;
