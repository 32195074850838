import React, { Component } from "react";
import InputSlider from "react-input-slider";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import "react-input-slider/dist/input-slider.css";

const SliderWrapper = styled.div`
  position: relative;
  margin: 10px;
  opacity: ${props => (props.disabled ? "0.5" : "1")};
	pointer-events: ${props => (props.disabled ? "none" : "auto")};

  .u-slider {
    position: relative;
    width: 100%;
    height: 7px;
    box-shadow: inset 0 1px 13px 0 rgba(0, 0, 0, 0.17);
    background-color: transparent;
    border: 1px solid ${props => props.theme.grey3};

    .value {
      background-color: transparent;
    }

    .handle {
      position: absolute;
      z-index: 800;

      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 40px;
        height: 43px;
        border-radius: 0;
        outline: none;
        border: none;
        font-size: 18px;
        padding-top: 8px;
        font-weight: bold;
        background-image:url("${props => props.theme.sliderBackground}");
        background-repeat: no-repeat;
        background-color: transparent;
        content: "${props => props.value}";
        top: 3px;
      }
    }
  }
`;

const LabelWrapper = styled.div`
  position: absolute;
  top: -5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: bold;
`;

const Value = styled.div`
  position: absolute;
  top: -25px;
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 1;

  ${breakpoint("xl")`
    opacity: 0;
  `}
`;

class Slider extends Component {
  state = {
    value: this.props.startValue,
    submittedValue: this.props.startValue,
    submitted: false,
    checkSubmit: false
  };

  componentDidMount() {
    const { input, changeFieldValue } = this.props;

    if (!this.state.checkSubmit) {
      setInterval(() => {
        changeFieldValue("wizard", input.name, this.state.value);
        this.setState({ checkSubmit: true });
      }, 2000);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.max !== this.props.max && this.state.value > nextProps.max) {
      this.setState({ value: nextProps.max });
    }
  }

  handleChange = value => {
    this.setState({ submitted: false, value: value.x });
  };

  handleSubmit = () => {
    const { input, changeFieldValue } = this.props;
    const { value } = this.state;

    this.setState({ submitted: true, submittedValue: value });
    changeFieldValue("wizard", input.name, value);
  };

  render() {
    const { min, max, disabled, labelDescription } = this.props;
    const { value, submitted } = this.state;

    return (
      <SliderWrapper disabled={disabled} value={value}>
        {!disabled && !submitted && (
          <Value>
            <p>
              {value}
              {labelDescription}
            </p>
          </Value>
        )}

        <LabelWrapper>
          <Label>
            {min} {labelDescription && labelDescription}
          </Label>
          <Label>
            {max} {labelDescription && labelDescription}
          </Label>
        </LabelWrapper>
        <InputSlider
          className="slider slider-x"
          axis="x"
          x={value}
          xmin={min}
          xmax={max}
          onChange={this.handleChange}
          onDragEnd={this.handleSubmit}
        />
      </SliderWrapper>
    );
  }
}

export default Slider;
