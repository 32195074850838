import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Icon from "../Icon";

const DividerButton = styled.button`
  position: absolute;
  cursor: pointer;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: ${props => props.theme.white};
  transition: background-color 0.2s linear, border 0.2s linear,
    color 0.2s linear;
  border: none;

  font-size: 12px;
  color: ${props => props.theme.black};

  &:hover {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.primaryHover};
  }

  &:focus {
    outline: none;
  }

  ${props =>
    props.size === "large" &&
    css`
      width: 270px;
    `}
`;

const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  margin: 40px auto;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid ${props => props.theme.black};
  transition: background-color 0.2s linear, border 0.2s linear,
    color 0.2s linear;

  &:hover {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.white};
  }
`;

const Title = styled.span`
  width: 100%;
  font-size: 16px;
  margin-top: -10px;

  ${props =>
    props.buttonType === "primary" &&
    css`
      padding: 10px 30px;
      margin-top: 0px;
    `}
`;

const Button = ({ title, onClick, size, buttonType }) => (
  <React.Fragment>
    {buttonType === "divider" && (
      <DividerButton size={size} onClick={onClick}>
        <Title buttonType={buttonType}>{title}</Title>
        <Icon
          absolute
          style={{ top: 45, left: 0, right: 0 }}
          className="icon-arrow-down"
        />
        <Icon
          absolute
          style={{ top: 37, left: 0, right: 0 }}
          className="icon-arrow-down"
        />
      </DividerButton>
    )}
    {buttonType === "primary" && (
      <StyledButton onClick={onClick}>
        <Icon size={{ fontSize: 12 }} className="icon-arrow-up" />
        <Title buttonType={buttonType}>{title}</Title>
      </StyledButton>
    )}
  </React.Fragment>
);

Button.defaultProps = {
  title: "Title",
  buttonType: "divider",
  onClick: () => console.log("clicked")
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
  buttonType: PropTypes.string
};
export default Button;
