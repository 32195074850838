import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

const ImageStepWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 100%;

  ${breakpoint("md")`
  max-width: ${props => props.width};
  min-width: ${props => props.width};
  flex-wrap: no-wrap;
  flex-basis: 0;
  `}
`;

export const Arrow = styled.img`
  widtH: 80px;
  transform: rotate(90deg);

  ${props =>
    props.scale &&
    css`
      transform: rotate(90deg) scale(1, -1);
    `}

  ${breakpoint("md")`
    width: 50px;
    padding-top: 50px;
    transform: none;

    ${props =>
      props.scale &&
      css`
        transform: scale(1, -1);
        padding-bottom: 50px;
      `}
  `}

  ${breakpoint("lg")`
        padding-top: 0px;
        width: auto;

        ${props =>
          props.scale &&
          css`
            transform: scale(1, -1);
            padding-bottom: 0px;
          `}
    `}
`;

const ImageStep = ({ children, width }) => {
  return <ImageStepWrapper width={width}>{children}</ImageStepWrapper>;
};

ImageStep.defaultProps = {
  width: "auto"
};

ImageStep.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string
}

export default ImageStep;
