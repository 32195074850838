import {injectGlobal} from 'styled-components';

const defaultTheme = {
	/*
	==========================================================================
	Variables
	==========================================================================
	*/

	// Colors
	alert: 'red',
	black: 'black',
	primary: 'blue',
	primaryHover: 'pink',
	success: 'green',
	white: 'white',

	// Fonts
	ffRegular: '"Helvetica Neue", Helvetica, Arial, sans-serif'
};

injectGlobal`
	body {
		font-family: ${defaultTheme.ffRegular};
		font-size: 1.6rem;
	}
	
	h1, h2, h3, h4 ,h5 {
		
		* > &:first-child {
			margin-top: 0;
		}
	}
`;

export default defaultTheme;
