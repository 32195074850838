import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/types";

const INITIAL_STATE = {
  error: undefined,
  success: undefined,
  info: undefined
};

const messagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        [action.messageType]: action.message
      };

    case CLEAR_MESSAGE:
      return {
          ...state,
          [action.payload]: undefined
      }
    
    default:
      return state;
  }
};

export default messagesReducer;
