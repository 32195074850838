import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.white};
  padding: 60px 0px;
  
  &:hover {
    background-image: url("${props => props.theme.stepActiveBackground}");
    cursor: pointer;
  }

  ${props =>
    props.active &&
    css`
    background-image: url("${props => props.theme.stepActiveBackground}");
  `}
`;

Wrapper.propTypes = {
  active: PropTypes.bool
};

const ContentBox = ({ active, children, id }) => {
  return (
    <Wrapper id={id} active={active}>
      <div className="container">{children}</div>
    </Wrapper>
  );
};

ContentBox.defaultProps = {
  active: false
};

ContentBox.propTypes = {
  id: PropTypes.number.isRequired,
  active: PropTypes.bool,
  children: PropTypes.node
};

export default ContentBox;
