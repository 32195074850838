import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, change, Field } from "redux-form";
import { injectIntl } from "react-intl";
import { animateScroll as scroll } from "react-scroll";
import { setStep } from "../../actions/step";
import { calculatePbSport } from "../../actions/results";
import { determineFaktor } from "../../helpers/selectors";
import validate from "../../helpers/validateWizard";

import ContentBox from "../../components/ContentBox/ContentBox";
import ContentTitle from "../../components/ContentTitle";
import InputGroup from "../../components/InputGroup";
import RadioInput from "../../components/RadioInput";
import Divider from "../../components/Divider";
import InfoBox from "../../components/InfoBox";
import { scrollTo } from "../../helpers/iframe";
import Info from "../../components/Info";

class Step2 extends Component {
  constructor(props) {
    super(props);

    this.step = 2;

    this.state = {
      showInfo: false
    };

    this._onSubmit = this._onSubmit.bind(this);
    this._toggleInfo = this._toggleInfo.bind(this);
  }

  componentDidMount() {
    const { getContainerHeight, calculateScrollOffset } = this.props;

    const offset = document.getElementById(this.step).offsetTop;
    scroll.scrollTo(offset);
    scrollTo(offset);

    getContainerHeight(this.step);
    calculateScrollOffset(this.step);

    document.getElementById(this.step).addEventListener("mouseenter", () => {
      calculateScrollOffset(this.step);
      getContainerHeight(this.step);
    });
  }

  componentDidUpdate() {
    const {
      step,
      getContainerHeight,
      calculatePbSport,
      pbTag,
      values
    } = this.props;
    getContainerHeight(this.step);

    if (step > this.step) {
      calculatePbSport(pbTag, determineFaktor(values.activity));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { changeFieldValue, values } = this.props;
    if (values.pregnant !== "yes" && nextProps.values.pregnant === "yes") {
      changeFieldValue("wizard", "activity", "never");
    }
  }

  _onSubmit() {
    const { setStep, calculatePbSport, pbTag, values } = this.props;
    setStep(this.step + 1);
    calculatePbSport(pbTag, determineFaktor(values.activity));
  }

  _toggleInfo() {
    this.setState({ showInfo: !this.state.showInfo });
  }

  render() {
    const { step, intl, handleSubmit, values } = this.props;
    const { showInfo } = this.state;

    const showAllFields = values.sex === "male" || values.pregnant !== "yes";

    return (
      <React.Fragment>
        <ContentBox id={this.step} active={step === this.step}>
          <Info
            smallDevice
            onClick={this._toggleInfo}
            active={showInfo}
            infoText={intl.formatMessage({ id: "step2.info.text" })}
          />
          <form>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <ContentTitle
                  step={this.step}
                  title={intl.formatMessage({ id: "step2.title" })}
                />
              </div>
              <div className="col-lg-9 col-md-12">
                <Info
                  onClick={this._toggleInfo}
                  active={showInfo}
                  infoText={intl.formatMessage({ id: "step2.info.text" })}
                />
                <InputGroup
                  justifyContent={
                    values.sex === "female" && values.pregnant === "yes"
                      ? "space-around"
                      : "space-between"
                  }
                >
                  <Field
                    name="activity"
                    type="radio"
                    value={"never"}
                    component={RadioInput}
                    label={intl.formatMessage({
                      id: "step2.activity.label.option1"
                    })}
                  />
                  <Field
                    name="activity"
                    type="radio"
                    value={"regulary"}
                    component={RadioInput}
                    label={intl.formatMessage({
                      id: "step2.activity.label.option2"
                    })}
                  />
                  {showAllFields && (
                    <React.Fragment>
                      {" "}
                      <Field
                        name="activity"
                        type="radio"
                        value={"intense"}
                        component={RadioInput}
                        label={intl.formatMessage({
                          id: "step2.activity.label.option3"
                        })}
                      />
                      <Field
                        name="activity"
                        type="radio"
                        value={"optimization"}
                        component={RadioInput}
                        label={intl.formatMessage({
                          id: "step2.activity.label.option4"
                        })}
                      />
                      <Field
                        name="activity"
                        type="radio"
                        value={"power"}
                        component={RadioInput}
                        label={intl.formatMessage({
                          id: "step2.activity.label.option5"
                        })}
                      />
                    </React.Fragment>
                  )}
                </InputGroup>
                {values.activity && (
                  <InfoBox>
                    {values.activity === "never" &&
                      intl.formatMessage({
                        id: `step2.activity.description.option1`
                      })}
                    {values.activity === "regulary" &&
                      intl.formatMessage({
                        id: `step2.activity.description.option2`
                      })}
                    {values.activity === "intense" &&
                      intl.formatMessage({
                        id: `step2.activity.description.option3`
                      })}
                    {values.activity === "optimization" &&
                      intl.formatMessage({
                        id: `step2.activity.description.option4`
                      })}
                    {values.activity === "power" &&
                      intl.formatMessage({
                        id: `step2.activity.description.option5`
                      })}
                  </InfoBox>
                )}
              </div>
            </div>
          </form>
        </ContentBox>
        <Divider
          withButton={step <= 2}
          buttonTitle={intl.formatMessage({ id: "button.next" }).toLowerCase()}
          buttonOnClick={handleSubmit(this._onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    step: state.step,
    values: state.form.wizard.values,
    pbTag: state.results.pbTag
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStep: step => dispatch(setStep(step)),
    calculatePbSport: (pbTag, faktor) =>
      dispatch(calculatePbSport(pbTag, faktor)),
    changeFieldValue: (form, field, value) =>
      dispatch(change(form, field, value))
  };
};

export default reduxForm({
  form: "wizard",
  destroyOnUnmount: false,
  validate
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Step2))
);
