import { SET_PB_SPORT, SET_PB_TAG, SET_PRODUCT, RESET } from "./types";
import { round } from "../helpers/functions";

export const reset = () => {
  return {
    type: RESET
  };
};

/**
 * BLOCK 3
 * calculatePbTag() returns an action with PBtag as payload
 * PBtag: Proteintagesbedarf (ohne sportliche Aktivität)
 *
 * @param {Object} param0
 *
 * @return {Object}
 */
export const calculatePbTag = ({ age, weight, sex, pregnant, pregnantIn }) => {
  let pbTag,
    pb = 0;
  if (age >= 18 && age <= 19) {
    sex === "female" ? (pb = 0.9) : (pb = 0.8);
  }
  if (age >= 20 && age < 65) {
    pb = 0.8;
  }
  if (age >= 65) {
    pb = 1.0;
  }
  if (sex === "female" && pregnant === "yes") {
    pregnantIn === "1" && (pb = 0.8);
    pregnantIn === "2" && (pb = 0.9);
    pregnantIn === "3" && (pb = 1.0);
  }
  if (sex === "female" && pregnant === "suckle") {
    pb = 1.2;
  }
  pbTag = Math.round(pb * weight);

  return {
    type: SET_PB_TAG,
    payload: pbTag
  };
};

/**
 * BLOCK 5
 * calculatePbSport() returns an action with PBsport as payload
 * PBsport: Sportlicher Proteintagesbedarf
 *
 * @param {Number} pbTag
 * @param {Number} faktor
 *
 * @return {Object}
 */
export const calculatePbSport = (pbTag, faktor) => {
  const pbSport = Math.round(pbTag * faktor);
  return {
    type: SET_PB_SPORT,
    payload: pbSport
  };
};

/**
 * BLOCK 6
 * calculatePbSupp() returns an action with PBsupp as payload
 * PBsupp: Zu supplementierender Proteinbedarf durch Shakes pro Tag
 *
 * @param {Number} pbSport
 * @param {Object} param1
 *
 * @return {Object}
 */
export const calculatePbSupp = (
  pbSport,
  { dailyreq, dailyreqgram, dailyreqpercent }
) => {
  switch (dailyreq) {
    case "gram":
      return dailyreqgram;
    case "percent":
      return pbSport * (dailyreqpercent / 100);
    default:
      return 0;
  }
};

/**
 * BLOCK 7
 * setProduct() returns an action with the selected product as payload
 *
 * @param {String} productId
 * @param {Object} param1
 *
 * @return {Object}
 */
export const setProduct = (id, products) => {
  var productId = parseInt(id.split("|")[0]);
  const product = products.filter(product => {
    return product.id === productId;
  });
  return {
    type: SET_PRODUCT,
    payload: product[0]
  };
};

/**
 * BLOCK 8 -10
 * makeFinalCalculations() returns an action with MPU, AM, N and Y as payload
 * MPU: Menge an ausgewähltem Pulver (in gramm), die man benötigt, um PBsupp bei ausgewähltem Auflösemedium zu erreichen
 * AM: Menge an Auflösemedium für MPU in ml
 * Y: Anzahl der Shakes am Tag
 * N: Menge Pulver (in gram) in einem Shake, die benötigt wird um PBsupp bei Y Shakes am Tag zu decken
 *  
 * @param {Number} pam 
 * @param {Object} results
 
 * @return {Object} 
 */
export const makeFinalCalculations = (pam, { product, pbSport }, values) => {
  let finalData = {},
    mpu,
    am,
    n,
    y,
    ycalc = 0;

  const pp = product.protein / 100;
  const pbSupp = calculatePbSupp(pbSport, values);

  switch (product.protein_product_type) {
    case "WPI":
    case "3K":
      // Rechnung Block 8
      mpu = Math.pow((pp + 0.1 * pam) / pbSupp, -1);

      // Rechnung Block 10 Punkt 1
      am = 10 * mpu;
      finalData.mpu = mpu;
      finalData.am = Math.ceil(am);
      break;
    case "SPI":
      // Rechnung Block 8
      mpu = Math.pow((pp + 0.15 * pam) / pbSupp, -1);

      // Rechnung Block 10 Punkt 1
      am = 15 * mpu;
      finalData.mpu = mpu;
      finalData.am = Math.ceil(am);
      break;
    default:
      mpu = 0;
      am = 0;
  }

  // Rechnung BLOCK 10 Punkt 2
  ycalc = Math.round(am / 300);
  if (am / ycalc > 300) {
    y = ycalc + 1;
  }
  if (am / ycalc < 300) {
    y = ycalc;
  }
  finalData.y = y;

  n = round(mpu / y);
  finalData.n = Math.ceil(n);

  switch (product.protein_product_type) {
    case "WPI":
    case "3K":
      finalData.ami = Math.ceil(10 * n);
      break;
    case "SPI":
      finalData.ami = Math.ceil(15 * n);
      break;
    default:
      finalData.ami = 0;
  }

  return finalData;
};
