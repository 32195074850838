import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { reduxForm, change } from "redux-form";
import { animateScroll as scroll } from "react-scroll";
import { setStep } from "../../actions/step";
import validate from "../../helpers/validateWizard";
import { calculatePbSupp } from "../../actions/results";

import Divider from "../../components/Divider";
import ContentBox from "../../components/ContentBox/ContentBox";
import ContentTitle from "../../components/ContentTitle";
import Circle from "../../components/Circle";
import InputGroup from "../../components/InputGroup";
import { scrollTo } from "../../helpers/iframe";
import Info from "../../components/Info";

class Step3 extends Component {
  constructor(props) {
    super(props);

    this.step = 3;

    this.state = {
      showInfo: false
    };

    this._onSubmit = this._onSubmit.bind(this);
    this._toggleInfo = this._toggleInfo.bind(this);
  }

  componentDidMount() {
    const { getContainerHeight, calculateScrollOffset } = this.props;

    const offset = document.getElementById(this.step).offsetTop;
    scroll.scrollTo(offset);
    scrollTo(offset);

    getContainerHeight(this.step);
    calculateScrollOffset(this.step);

    document.getElementById(this.step).addEventListener("mouseenter", () => {
      calculateScrollOffset(this.step);
      getContainerHeight(this.step);
    });
  }

  componentDidUpdate() {
    const { getContainerHeight } = this.props;

    getContainerHeight(this.step);
  }

  _onSubmit() {
    const { setStep } = this.props;
    setStep(this.step + 1);
  }

  _toggleInfo() {
    this.setState({ showInfo: !this.state.showInfo });
  }

  render() {
    const { step, intl, handleSubmit, results } = this.props;
    const { showInfo } = this.state;

    return (
      <React.Fragment>
        <ContentBox id={this.step} active={step === this.step}>
          <Info
            smallDevice
            onClick={this._toggleInfo}
            active={showInfo}
            infoText={intl.formatMessage({ id: "step3.info.text" })}
          />
          <form>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <ContentTitle
                  step={this.step}
                  title={intl.formatMessage({ id: "step3.title" })}
                />
              </div>
              <div className="col-lg-9 col-md-12">
                <Info
                  onClick={this._toggleInfo}
                  active={showInfo}
                  infoText={intl.formatMessage({ id: "step3.info.text" })}
                />
                <InputGroup width={"70%"} center wrap={false}>
                  <Circle
                    size="small"
                    pb={results.pbTag}
                    label={intl.formatMessage({ id: "step3.pb.label" })}
                    description={intl.formatMessage({
                      id: "step3.pb.day.description"
                    })}
                  />
                  <Circle
                    size="large"
                    pb={results.pbSport}
                    label={intl.formatMessage({ id: "step3.pb.label" })}
                    description={intl.formatMessage({
                      id: "step3.pb.sport.description"
                    })}
                  />
                </InputGroup>
              </div>
            </div>
          </form>
        </ContentBox>
        <Divider
          withButton={step <= 3}
          buttonTitle={intl.formatMessage({ id: "step3.button.next" })}
          buttonOnClick={handleSubmit(this._onSubmit)}
          buttonSize="large"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    step: state.step,
    values: state.form.wizard.values,
    results: state.results
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStep: step => dispatch(setStep(step)),
    calculatePbSupp: (pbsport, values) =>
      dispatch(calculatePbSupp(pbsport, values)),
    changeFieldValue: (form, field, value) =>
      dispatch(change(form, field, value))
  };
};

export default reduxForm({
  form: "wizard",
  destroyOnUnmount: false,
  validate
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Step3))
);
