export const scrollTo = offset => {
  let target = window.parent.postMessage
    ? window.parent
    : window.parent.document.postMessage
    ? window.parent.document
    : undefined;
  target.postMessage({ scrollTo: offset }, "*");
  return true;
};

export const setHeight = height => {
  let target = window.parent.postMessage
    ? window.parent
    : window.parent.document.postMessage
    ? window.parent.document
    : undefined;

  target.postMessage(height, "*");
};
