export const determineFaktor = activity => {
  switch (activity) {
    case "never":
      return 1;
    case "regulary":
      return 1.5;
    case "intense":
      return 1.75;
    case "optimization":
      return 1.875;
    case "power":
      return 2;
    default:
      return 0;
  }
};

export const determinePam = ({ preparation, preparationAlt }) => {
  let pam = 0;
  switch (preparation) {
    case "water":
      pam = 0;
      break;
    case "milk":
      pam = 3.37;
      break;
    case "vegan":
      pam = preparationAlt;
      break;
    default:
      pam = 0;
  }
  return pam;
};

export const determinePreparation = preparation => {
  let description;
  switch (preparation) {
    case "water":
      description = "Wasser";
      break;
    case "milk":
      description = "Milch";
      break;
    case "vegan":
      description = "veganer Milchalternative";
      break;
    default:
      break;
  }
  return description;
};

export const sortProducts = products => {
  return products.sort((a, b) => {
    const categoryA = a.product_line;
    const categoryB = b.product_line;
    if (categoryA < categoryB) {
      return -1;
    }
    if (categoryA > categoryB) {
      return 1;
    }
    return 0;
  });
};
