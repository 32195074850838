import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

const InfoWrapper = styled.div`
  position: absolute;
  z-index: 900;
  right: 0;
  top: 0px;
  display: none;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border: 2px solid ${props => props.theme.black};
  border-top: none;

  ${props =>
    props.smallDevice &&
    css`
      display: flex;
    `}

  ${breakpoint("lg")`
    display: flex;
    top: -60px;

    ${props =>
      props.smallDevice &&
      css`
        display: none;
      `}
  `}
`;

const Icon = styled.span`
  font-size: 24px;
  font-weight: bold;

  ${props =>
    props.warning &&
    css`
      color: ${props => props.theme.primary};
    `}
`;

const Content = styled.div`
  position: absolute;
  z-index: 850;
  top: -60px;
  right: 0;
  display: none;
  background-color: ${props => props.theme.white};
  padding: 70px;
  border: 1px solid ${props => props.theme.grey3};

  ${props =>
    props.smallDevice &&
    css`
      display: block;
      top: 0px;
    `}

  ${breakpoint("lg")`
  display: block;
  top: -60px;

  ${props =>
    props.smallDevice &&
    css`
      display: none;
    `}
`}
`;

const createMarkup = text => {
  return { __html: text };
};

const Info = ({
  active,
  infoTitle,
  infoText,
  onClick,
  warning,
  smallDevice
}) => {
  return (
    <React.Fragment>
      <InfoWrapper smallDevice={smallDevice} onClick={onClick}>
        <Icon warning={warning}>{active ? "X" : "i"}</Icon>
      </InfoWrapper>
      {active && (
        <Content smallDevice={smallDevice}>
          <p>
            <b>{infoTitle}</b>
          </p>
          <div dangerouslySetInnerHTML={createMarkup(infoText)} />
        </Content>
      )}
    </React.Fragment>
  );
};

Info.defaultProps = {
  active: false,
  warning: false,
  smallDevice: false
};

Info.propTypes = {
  active: PropTypes.bool,
  warning: PropTypes.bool,
  infoText: PropTypes.string,
  infoTitle: PropTypes.string,
  onClick: PropTypes.func,
  smallDevice: PropTypes.bool
};

export default Info;
