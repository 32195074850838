export const round = number => {
  let rest = number % 1;
  if (rest === 0) {
    return number;
  }
  if (rest <= 0.5) {
    return number - rest + 0.5;
  }
  if (rest > 0.5) {
    return number - rest + 1;
  }
};

export const roundAmi = number => {
  const roundedNumber = Math.round(number / 50) * 50;
  if (roundedNumber <= 25 && roundedNumber > 0) {
    return 50;
  }
  return roundedNumber;
};
