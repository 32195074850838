import React, { Component } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { animateScroll as scroll } from "react-scroll";
import { setStep } from "../../actions/step";
import {
  reset as resetResults,
  makeFinalCalculations
} from "../../actions/results";
import { determinePreparation, determinePam } from "../../helpers/selectors";

import Button from "../../components/Button";
import ImageStep, { Arrow } from "../../components/ImageStep";
import ContentBox from "../../components/ContentBox/ContentBox";
import InputGroup from "../../components/InputGroup";
import { scrollTo } from "../../helpers/iframe";
import { roundAmi } from "../../helpers/functions";

class Step7 extends Component {
  constructor(props) {
    super(props);

    this.step = 7;

    this.state = {
      result: {
        y: 0,
        n: 0
      }
    };

    this._resetForm = this._resetForm.bind(this);
  }
  componentDidMount() {
    const {
      getContainerHeight,
      calculateScrollOffset,
      pam,
      results,
      values
    } = this.props;

    const offset = document.getElementById(this.step).offsetTop;
    scroll.scrollTo(offset);
    scrollTo(offset);

    getContainerHeight(this.step);
    calculateScrollOffset(this.step);

    document.getElementById(this.step).addEventListener("mouseenter", () => {
      calculateScrollOffset(this.step);
      getContainerHeight(this.step);
    });

    this.setState({
      result: makeFinalCalculations(pam, results, values)
    });
  }

  componentWillReceiveProps(nextProps) {
    const { pam, results, values } = nextProps;
    this.setState({ result: makeFinalCalculations(pam, results, values) });
  }

  _resetForm() {
    const { reset, resetResults, setStep } = this.props;

    const offset = document.getElementById(1).offsetTop;
    scroll.scrollTo(offset);
    scrollTo(0);

    reset("wizard");

    resetResults();
    setStep(1);
  }

  render() {
    const { step, theme, intl, results, values } = this.props;
    const { result } = this.state;
    return (
      <ContentBox id="7" active={step === 7}>
        <div className="row">
          <div className="col-lg-12">
            <h2>
              UNSERE EMPFEHLUNG FÜR DICH: <br /> Täglich {result.y} Shake
              {result.y > 1 && "s"} {results.product.name}
            </h2>
            <p style={{ textAlign: "center" }}>
              Trinke{" "}
              <b>
                {result.y} Shake
                {result.y > 1 && "s"} pro Tag
              </b>
              , mit je{" "}
              <b>
                {result.n} g {results.product.name}
              </b>{" "}
              in{" "}
              <b>
                {roundAmi(result.ami)} ml{" "}
                {determinePreparation(values.preparation)}
              </b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <InputGroup justifyContent="space-around" width="80%" autoMargin>
              <ImageStep width="140px">
                <img alt="" src={theme.step7description01} />
                <p style={{ textAlign: "center" }}>
                  {roundAmi(result.ami)} ml Flüssigkeit in einen Shaker geben
                </p>
              </ImageStep>
              <ImageStep>
                <Arrow src={theme.step7arrow} />
              </ImageStep>
              <ImageStep width="250px">
                <div>
                  <img
                    alt=""
                    style={{ maxHeight: 120 }}
                    src={results.product.img}
                  />
                  <img alt="" src={theme.step7plus} />
                  <img alt="" src={theme.step7description02} />
                </div>
                <p style={{ textAlign: "center", maxWidth: 120 }}>
                  {results.product.name} hinzufügen
                </p>
              </ImageStep>
              <ImageStep>
                <Arrow scale src={theme.step7arrow} />
              </ImageStep>
              <ImageStep width="140px">
                <img alt="" src={theme.step7description03} />
                <p style={{ textAlign: "center" }}>
                  Gut schütteln und genießen
                </p>
              </ImageStep>
            </InputGroup>
          </div>
          <div className="col-lg-12">
            <Button
              title={intl.formatMessage({ id: "step7.button.title" })}
              buttonType="primary"
              onClick={() => this._resetForm()}
            />
          </div>
        </div>
      </ContentBox>
    );
  }
}

const mapStateToProps = state => {
  return {
    step: state.step,
    results: state.results,
    values: state.form.wizard.values,
    pam: determinePam(state.form.wizard.values)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStep: step => dispatch(setStep(step)),
    reset: form => dispatch(reset(form)),
    resetResults: () => dispatch(resetResults())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withTheme(Step7)));
