import { NEXT_STEP, PREVIOUS_STEP, SET_STEP } from "./types";

/**
 * setStep() returns an action with step as payload
 *
 * @param {Number} step
 *
 * @return {Object}
 */
export const setStep = (step = 1) => {
  return {
    type: SET_STEP,
    payload: step
  };
};

/**
 * @return {Object}
 */
export const nextStep = () => {
  return {
    type: NEXT_STEP
  };
};

/**
 * @return {Object}
 */
export const previousStep = () => {
  return {
    type: PREVIOUS_STEP
  };
};
