import React, { Component } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

const AnimatedSelectWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;

  ${breakpoint("md")`
  width: auto !important;
  flex-direction: column;
  padding: 0px;
`}
`;

const Description = styled.span`
  font-size: 22px;
  margin: 15px 0px;
  color: ${props => (props.error ? props.theme.error : props.theme.black)};
`;

const LottieWrapper = styled.div`
  transition: padding 0.2s linear;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const Label = styled.a`
  text-decoration: none;
  color: ${props => props.theme.black};
  font-size: 16px;
  transition: font-size 500ms ease;

  &:hover {
    color: ${props => props.theme.black};
    text-decoration: none;
  }

  ${props =>
    props.active &&
    css`
      font-family: ${props => props.theme.ffBold};
      font-size: 24px;
    `};
`;

class AnimatedSelect extends Component {
  constructor(props) {
    super(props);

    this._changeGender = this._changeGender.bind(this);
  }

  _changeGender(gender) {
    this.props.changeFieldValue("wizard", "sex", gender);
  }

  render() {
    const { description, animationOptions, gender } = this.props;
    return (
      <AnimatedSelectWrapper>
        <Description>{description}</Description>
        <div style={{ minWidth: 145 }}>
          <LottieWrapper
            style={gender === "female" ? { paddingRight: 20 } : {}}
          >
            <Lottie
              options={
                gender === "male" ? animationOptions.out : animationOptions.in
              }
              width={60}
              height={60}
            />
          </LottieWrapper>
          <Labels>
            <Label
              active={gender === "male"}
              onClick={() => this._changeGender("male")}
            >
              Mann
            </Label>
            <Label
              active={gender === "female"}
              onClick={() => this._changeGender("female")}
            >
              Frau
            </Label>
          </Labels>
        </div>
      </AnimatedSelectWrapper>
    );
  }
}

AnimatedSelect.defaultProps = {
  description: "Plese select your gender",
  changeFieldValue: () => console.log("clicked")
};

AnimatedSelect.propTypes = {
  description: PropTypes.string.isRequired,
  animationOptions: PropTypes.object.isRequired,
  changeFieldValue: PropTypes.func.isRequired
};

export default AnimatedSelect;
