/**
 * validate() returns an Object with errors found during validation
 *
 * @param {Object} values
 *
 * @return {Object}
 */

const validate = values => {
  const errors = {};
  if (!values.weight) {
    errors.weight = "Please enter your weight";
  }
  if (!values.age) {
    errors.age = "Please enter your age";
  }
  if (!values.sex) {
    errors.sex = "Please enter your sex";
  }
  if (!values.pregnant) {
    errors.pregnant = "Please make a decision";
  }
  if (values.pregnant === "yes" && !values.pregnantIn) {
    errors.pregnantIn = "Please make a deciscion";
  }
  if (!values.activity) {
    errors.activity = "Plese choose a activity";
  }
  if (!values.product) {
    errors.product = "Plese choose a product";
  }
  if (!values.preparation) {
    errors.preparation = "Plese choose a preparation";
  }
  if (values.preparation === "vegan" && !values.preparationAlt) {
    errors.preparationAlt = "Plese choose a preparation";
  }
  return errors;
};

export default validate;
