export const SET_PB_TAG = "set_pb_tag";
export const SET_PB_SPORT = "set_pb_sport";
export const SET_PB_SUPP = "set_pb_supp";
export const SET_PRODUCT = "set_product";
export const SET_RESULT = "set_result";

export const NEXT_STEP = "next_step";
export const PREVIOUS_STEP = "previous_step";
export const SET_STEP = "set_step";

export const GET_PRODUCTS = "get_products";

export const RESET = "reset";

export const SET_MESSAGE = "set_message";
export const CLEAR_MESSAGE = "clear_message";
