import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Divider from "../Divider";

const Image = styled.img`
  max-width: 120px;
  transition: transform .3s ease-in-out;

  &:hover {
    transform: scale(1.1)
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  align-items: center;
  cursor: pointer;
  transition: max-width 300ms ease;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.black};
  padding-top: 15px;
`;

const Description = styled.span`
  font-size: 16px;
`;

const Product = ({ name, id, flavour, img, onProductChange, children }) => {
  return (
    <React.Fragment>
      <Item onClick={() => onProductChange("wizard", "product", id + name)}>
        <Image alt={name} src={img} />
        <Title>{name}</Title>
        <Divider dark margin={5} />
        <Description>{flavour}</Description>
      </Item>
      {children}
    </React.Fragment>
  );
};

Product.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
  flavour: PropTypes.string,
  onProductChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default Product;
