import axios from "axios";
import { GET_PRODUCTS } from "./types";
import { setMessage, clearMessage } from "./messages";
import { API_ROOT } from "../config";

/**
 * getProducts() returns an action with all fetched products as payload
 *
 * @param {Object} products
 */
const getProducts = products => {
  return {
    type: GET_PRODUCTS,
    payload: products
  };
};

/**
 * startGetProducts() returns a function to perform asynchronous dispatch
 *
 */
export const startGetProducts = offline => {
  return async dispatch => {
    try {
      const products = await axios.get(API_ROOT, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (offline) {
        const localProducts = JSON.parse(localStorage.getItem("products"));
        dispatch(getProducts(localProducts));
      } else {
        dispatch(getProducts(products.data));
        localStorage.setItem("products", JSON.stringify(products.data));
      }

      dispatch(clearMessage("error"));
    } catch (err) {
      if (offline) {
        const localProducts = JSON.parse(localStorage.getItem("products"));
        dispatch(getProducts(localProducts));
        dispatch(clearMessage("error"));
      } else {
        dispatch(
          setMessage(
            "Die Produkte für den Protein Coach konnten leider nicht geladen werden. Bitte versuche es später noch einmal.",
            "error"
          )
        );
      }
    }
  };
};
