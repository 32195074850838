import {
  SET_PB_SPORT,
  SET_PB_TAG,
  SET_PB_SUPP,
  SET_PRODUCT,
  SET_RESULT,
  RESET
} from "../actions/types";

const INITIAL_STATE = {
  pbTag: undefined,
  pbSport: undefined,
  pbSupp: undefined,
  product: undefined,
  result: undefined
};

const resultsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET:
      return INITIAL_STATE;
    case SET_PB_TAG:
      return { ...state, pbTag: action.payload };
    case SET_PB_SPORT:
      return { ...state, pbSport: action.payload };
    case SET_PB_SUPP:
      return { ...state, pbSupp: action.payload };
    case SET_PRODUCT:
      return { ...state, product: action.payload };
    case SET_RESULT:
      return { ...state, result: action.payload };
    default:
      return state;
  }
};

export default resultsReducer;
