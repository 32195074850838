import { NEXT_STEP, PREVIOUS_STEP, SET_STEP } from "../actions/types";
const INITIAL_STATE = 1;

const stepReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEXT_STEP:
      return state + 1;
    case PREVIOUS_STEP:
      return state - 1;
    case SET_STEP:
      return action.payload;
    default:
      return state;
  }
};

export default stepReducer;
