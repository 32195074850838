import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
import { animateScroll as scroll } from "react-scroll";
import { setStep } from "../../actions/step";
import validate from "../../helpers/validateWizard";
import { calculatePbSupp } from "../../actions/results";

import ContentBox from "../../components/ContentBox/ContentBox";
import ContentTitle from "../../components/ContentTitle";
import Slider from "../../components/Slider/Slider";
import RadioInput from "../../components/RadioInput";
import Divider from "../../components/Divider";
import Info from "../../components/Info";
import { scrollTo } from "../../helpers/iframe";

class Step4 extends Component {
  constructor(props) {
    super(props);

    this.step = 4;

    this.state = {
      showInfo: false
    };

    this._onSubmit = this._onSubmit.bind(this);
    this._toggleInfo = this._toggleInfo.bind(this);
  }

  componentDidMount() {
    const { getContainerHeight, calculateScrollOffset } = this.props;

    const offset = document.getElementById(this.step).offsetTop;
    scroll.scrollTo(offset);
    scrollTo(offset);

    getContainerHeight(this.step);
    calculateScrollOffset(this.step);

    document.getElementById(this.step).addEventListener("mouseenter", () => {
      calculateScrollOffset(this.step);
      getContainerHeight(this.step);
    });
  }

  componentWillReceiveProps(nextProps) {
    const { values, changeFieldValue } = this.props;
    if (
      values.dailyreq === "gram" &&
      nextProps.results.pbSport < values.dailyreqgram
    ) {
      changeFieldValue("wizard", "dailyreqgram", nextProps.results.pbSport);
    }
  }

  _onSubmit() {
    const { setStep } = this.props;
    setStep(this.step + 1);
  }

  _toggleInfo() {
    this.setState({ showInfo: !this.state.showInfo });
  }

  render() {
    const {
      step,
      intl,
      handleSubmit,
      values,
      results,
      changeFieldValue
    } = this.props;
    const { showInfo } = this.state;

    const warning =
      (values.dailyreq === "gram" &&
        values.dailyreqgram > results.pbSport / 2) ||
      (values.dailyreq === "percent" && values.dailyreqpercent >= 50);

    return (
      <React.Fragment>
        <ContentBox id={this.step} active={step === this.step}>
          <Info
            smallDevice
            onClick={this._toggleInfo}
            active={showInfo}
            warning={warning}
            infoTitle={intl.formatMessage({ id: "step4.info.title" })}
            infoText={intl.formatMessage({ id: "step4.info.text" })}
          />
          <form>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <ContentTitle
                  step={this.step}
                  title={intl.formatMessage({ id: "step4.title" })}
                />
              </div>
              <div className="col-lg-9 col-md-12">
                <Info
                  onClick={this._toggleInfo}
                  active={showInfo}
                  warning={warning}
                  infoTitle={intl.formatMessage({ id: "step4.info.title" })}
                  infoText={intl.formatMessage({ id: "step4.info.text" })}
                />
                <div className="row" style={{ marginTop: 60 }}>
                  <div className="col-lg-4">
                    <Field
                      horizontal
                      size="small"
                      name="dailyreq"
                      type="radio"
                      value="percent"
                      component={RadioInput}
                      label={intl.formatMessage({
                        id: "step4.dailyreq.label.option1"
                      })}
                    />
                  </div>
                  <div className="col-lg-8">
                    <Field
                      disabled={values && values.dailyreq === "gram"}
                      labelDescription={"%"}
                      min={1}
                      max={100}
                      name="dailyreqpercent"
                      type="text"
                      component={Slider}
                      changeFieldValue={changeFieldValue}
                      startValue={values.dailyreqpercent}
                    />
                  </div>
                </div>
                <Divider container={false} dark margin={50} />
                <div className="row">
                  <div className="col-lg-4">
                    <Field
                      horizontal
                      size="small"
                      name="dailyreq"
                      type="radio"
                      value="gram"
                      component={RadioInput}
                      label={intl.formatMessage({
                        id: "step4.dailyreq.label.option2"
                      })}
                    />
                  </div>
                  <div className="col-lg-8">
                    <Field
                      disabled={values && values.dailyreq === "percent"}
                      labelDescription={"g"}
                      min={1}
                      max={results.pbSport ? results.pbSport : 10}
                      name="dailyreqgram"
                      type="text"
                      component={Slider}
                      changeFieldValue={changeFieldValue}
                      startValue={values.dailyreqgram}
                    />
                  </div>
                </div>
                <div style={{ paddingTop: 20 }}>
                  <p>
                    <b>{intl.formatMessage({ id: "step4.example.title" })}</b>
                  </p>
                  <ul>
                    <li>
                      {intl.formatMessage({ id: "step4.example.option1" })}
                    </li>
                    <li>
                      {intl.formatMessage({ id: "step4.example.option2" })}
                    </li>
                    <li>
                      {intl.formatMessage({ id: "step4.example.option3" })}
                    </li>
                    <li>
                      {intl.formatMessage({ id: "step4.example.option4" })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </form>
        </ContentBox>
        <Divider
          withButton={step <= 4}
          buttonTitle={intl.formatMessage({ id: "button.next" }).toLowerCase()}
          buttonOnClick={handleSubmit(this._onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    step: state.step,
    values: state.form.wizard.values,
    results: state.results
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStep: step => dispatch(setStep(step)),
    calculatePbSupp: (pbsport, values) =>
      dispatch(calculatePbSupp(pbsport, values)),
    changeFieldValue: (form, field, value) =>
      dispatch(change(form, field, value))
  };
};

export default reduxForm({
  form: "wizard",
  destroyOnUnmount: false,
  validate
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Step4))
);
