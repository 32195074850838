import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
import { injectIntl } from "react-intl";
import { animateScroll as scroll } from "react-scroll";
import { setStep } from "../../actions/step";
import { startGetProducts } from "../../actions/products";
import validate from "../../helpers/validateWizard";
import { setProduct } from "../../actions/results";

import { sortProducts } from "../../helpers/selectors";

import RadioInput from "../../components/RadioInput";
import ContentBox from "../../components/ContentBox/ContentBox";
import Product from "../../components/Product/Product";
import ContentTitle from "../../components/ContentTitle";
import Divider from "../../components/Divider";
import { scrollTo } from "../../helpers/iframe";

class Step5 extends Component {
  constructor(props) {
    super(props);

    this.step = 5;

    this._onSubmit = this._onSubmit.bind(this);
  }

  componentDidMount() {
    const { getContainerHeight, calculateScrollOffset } = this.props;

    const offset = document.getElementById(this.step).offsetTop;
    scroll.scrollTo(offset);
    scrollTo(offset);

    getContainerHeight(this.step);
    calculateScrollOffset(this.step);

    document.getElementById(this.step).addEventListener("mouseenter", () => {
      calculateScrollOffset(this.step);
      getContainerHeight(this.step);
    });
  }

  componentDidUpdate() {
    const { step, setProduct, values, products } = this.props;

    if (step > this.step) {
      setProduct(values.product, products);
    }
  }

  _onSubmit() {
    const { setProduct, setStep, values, products } = this.props;

    setProduct(values.product, products);
    setStep(this.step + 1);
  }

  _renderProducts() {
    return this.props.products.map(product => {
      return (
        <div key={product.id} className="col-lg-4 col-6">
          <div style={{ marginBottom: 50 }}>
            <Product onProductChange={this.props.changeFieldValue} {...product}>
              <Field
                labelPosition="top"
                size="small"
                key={product.id}
                label={product.protein + " g Protein/Portion (100 g)"}
                name="product"
                type="radio"
                component={RadioInput}
                value={product.id + "|" + product.name}
              />
            </Product>
          </div>
        </div>
      );
    });
  }

  render() {
    const { intl, handleSubmit, step } = this.props;

    return (
      <React.Fragment>
        <ContentBox id={this.step} active={step === this.step}>
          <form>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <ContentTitle
                  step={this.step}
                  title={intl.formatMessage({ id: "step5.title" })}
                />
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="row">{this._renderProducts()}</div>
              </div>
            </div>
          </form>
        </ContentBox>
        <Divider
          withButton={step <= 5}
          buttonTitle={intl.formatMessage({ id: "button.next" }).toLowerCase()}
          buttonOnClick={handleSubmit(this._onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    step: state.step,
    values: state.form.wizard.values,
    products: sortProducts(state.products),
    errorMessage: state.messages.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStep: step => dispatch(setStep(step)),
    setProduct: (id, products) => dispatch(setProduct(id, products)),
    changeFieldValue: (form, field, value) =>
      dispatch(change(form, field, value)),
    startGetProducts: () => dispatch(startGetProducts())
  };
};

export default reduxForm({
  form: "wizard",
  destroyOnUnmount: false,
  validate
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Step5))
);
